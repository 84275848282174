import { ImageThumbnailRatio } from '@components/ImageThumbnailRatio';
import VnrLink from '@components/VnrLink';
import { Fragment } from 'react';

import styles from './PostGroup.module.scss';
import SmallPostItem from './SmallPostItem';

export const landingPageUrlList = [
  'phong-van-doc-quyen-ceo-connext',
  'phong-van-giam-doc-fullerton-martkets',
  'phong-van-giam-doc-fxgt-viet-nam',
  'phong-van-doc-quyen-ceo-onebid-tai-viet-nam',
  'phong-van-doc-quyen-giam-doc-dupoin',
  'dich-vu-forex-rebates-hoan-phi-forex-la-gi'
];

const NewPostGroup = ({ groupTitle, posts, linkLoadMore }) => {
  const latestPost = posts[0];
  if (!latestPost) return <div></div>;
  const { date, title, thumbnail, slug, author } = latestPost;

  const isLandingPage = landingPageUrlList.includes(slug);

  const publicDate = new Date(date);
  const formatDate = new Intl.DateTimeFormat('ru-RU', {
    dateStyle: 'short',
    timeStyle: 'short',
  }).format(publicDate);

  return (
    <div className={styles.groupWrapper}>
      <div className={styles.groupTitle}>{groupTitle}</div>
      <div className={styles.latestPostWrapper}>
        <div className={styles.latestPost}>
          <VnrLink to={isLandingPage ? slug : `${slug}.html`} className={styles.postThumbnail}>
            <ImageThumbnailRatio src={thumbnail} ratio="16:9" alt="thumbnail" />
          </VnrLink>
          <VnrLink to={isLandingPage ? slug : `${slug}.html`} className={styles.latestPostTitle}>
            {title?.rendered}
          </VnrLink>
          <div style={{ padding: '5px 0' }}>
            <span className={styles.postDetail}>{formatDate}</span>
            {author && (
              <VnrLink to={`/tac-gia/${author.slug}`} className={styles.postDetail}>
                &nbsp;&#8226; <span> {author.name}</span>
              </VnrLink>
            )}
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: latestPost?.excerpt?.rendered,
            }}
            className={styles.newPostExcerpt}
          ></div>
        </div>
      </div>

      {posts.slice(1, 4).map((item) => (
        <Fragment key={item.id}>
          <SmallPostItem item={item} />
        </Fragment>
      ))}

      <VnrLink to={linkLoadMore} className={styles.rebateRate}>
        Xem tất cả
      </VnrLink>
    </div>
  );
};
export default NewPostGroup;
