import AirdropCountdown from '@components/AirdropCountdown';
import { useAuth } from '@components/Auth';
import HomeTopSlider from '@components/BannerAds/HomeTopSlider/HomeTopSlider';
import Footer from '@components/Footer';
import HaravanProduct from '@components/HaravanProduct';
import HeaderBanner from '@components/HeaderBanner';
import MiddleBanner from '@components/HeaderBanner/MiddleBanner';
import IconTelegram from '@components/Icons/iconTelegram';
import { ImageThumbnailRatio } from '@components/ImageThumbnailRatio';
import Menu from '@components/Menu';
import { useModal } from '@components/ModalHook';
import NewPostGroup from '@components/PostGroupCategory/NewPostGroup';
import BackcomPopup, { POPUP_ID } from '@components/ProfileUpdateInfo/BackcomPopup';
import SEO from '@components/seo';
import Story from '@components/Story';
import VnrLink from '@components/VnrLink';
import { getHaravanProduct } from '@services/haravan.service';
import { getConfig } from '@services/story.service';
import { checkValidAgencyCode } from '@services/user.service';
import { setCookie } from '@utils/cookieUtil';
import { filterOnlyNeededDataPostGroup } from '@utils/postUtils';
import { getSliderBroker } from '@utils/sortBroker';
import { getQueryParams } from '@utils/urlUtil';
import { Col, Input, Row } from 'antd';
import {
  getAllCryptoBrokerDataCache,
  getAllForexBrokerDataCache,
} from 'cache-service/wpBrokerData';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { DOMAIN } from 'src/constants';
import useTranslate from 'src/hook/useTranslate';

import { getFeaturePostsCache } from '../cache-service/wpGroupPost';
import { getPostHaveCategoriesAndSize, getPostInCategoriesAndSize } from '../cache-service/wpPost';
import styles from './HomePageStyle.module.scss';
import dayjs from 'dayjs';
// dynamic
const LayoutPopup = dynamic(() => import('@components/BannerAds/LayoutPopup'), {
  ssr: false,
});
const PopupDisclaimer = dynamic(() => import('@components/popup/PopupDisclaimer'), {
  ssr: false,
});
const BrokerList = dynamic(() => import('@components/BrokerList'), {
  ssr: false,
});
const SlickBroker = dynamic(() => import('@components/SlickBroker'), {
  ssr: false,
});
const AcademySellingPoint = dynamic(() => import('@components/AcademySellingPoint'), {
  ssr: false,
});
const PopupUserSentInformation = dynamic(
  () => import('@components/popup/PopupUserSentInformation'),
  {
    ssr: false,
  }
);
const ProfileUpdateInfoPopup = dynamic(
  () => import('@components/ProfileUpdateInfo/ProfileUpdateInfoPopup'),
  {
    ssr: false,
  }
);
const HomeSliderForeign = dynamic(
  () => import('@components/BannerAds/HomeTopSlider/HomeSliderForeign'),
  {
    ssr: false,
  }
);
/// end dymamic

export const getPostsHaveCategoriesAndSize = async ({ categories, size }) => {
  const posts = await getPostHaveCategoriesAndSize({
    size: size,
    categories: categories,
  });
  return posts;
};

export const getStaticProps = async () => {
  //broker
  const forexBrokersData = await getAllForexBrokerDataCache();
  const cryptoBrokersData = await getAllCryptoBrokerDataCache();
  const newSlickBrokers = getSliderBroker(forexBrokersData);

  let forexBroker = forexBrokersData
    .filter((b) => b.acf.is_ranking_homepage)
    .sort((a, b) => a.acf.ranking_homepage_position - b.acf.ranking_homepage_position)
    .slice(0, 15);
  const newForexBroker = forexBroker.map((b) => {
    return {
      id: b.id,
      languageCode: b.languageCode,
      acf: {
        alt: b.slug,
        manage_by: b.acf.manage_by,
        lever_max: b.acf.lever_max,
        link_register: b.acf.link_register,
        link_rate:
          b?.acf?.link_rate
            ?.replace('https://vnrebates.net', DOMAIN)
            .replace('https://wp.vnrebates.io', DOMAIN) || null,
        rebate_money: b?.acf?.rebate_money || null,
      },
      thumbnail: b?.acf?.avatar?.url || null,
      slug: b.slug,
    };
  });

  let cryptoBroker = cryptoBrokersData
    .filter((b) => b.acf.is_ranking_homepage)
    .sort((a, b) => a.acf.ranking_homepage_position - b.acf.ranking_homepage_position)
    .slice(0, 15);
  const newCryptoBroker = cryptoBroker.map((b) => {
    return {
      id: b.id,
      languageCode: b.languageCode,
      acf: {
        alt: b.slug,
        crypto_broker_product: b.acf.crypto_broker_product,
        lever_max: b.acf.lever_max,
        link_register: b.acf.link_register,
        crypto_broker_lever: b.acf.crypto_broker_lever,
        link_rate:
          b?.acf?.link_rate
            ?.replace('https://vnrebates.net', DOMAIN)
            .replace('https://wp.vnrebates.io', DOMAIN) || null,
        is_decentralized_exchange: b.acf.is_decentralized_exchange || null,
        rebate_money: b?.acf?.rebate_money || null,
        uu_dai_mo_tai_khoan: b?.acf?.uu_dai_mo_tai_khoan || null,
        crypto_info_rebates: b?.acf?.crypto_info_rebates || null,
        crypto_broker_transition_cost: b?.acf?.crypto_broker_transition_cost || null
      },
      thumbnail: b?.acf?.avatar?.url || null,
      slug: b.slug,
    };
  });

  // group category
  let postsCateTradingCrypto = await getPostsHaveCategoriesAndSize({
    categories: [174],
    size: 5,
  });
  postsCateTradingCrypto = filterOnlyNeededDataPostGroup(postsCateTradingCrypto);
  postsCateTradingCrypto.push({
    id: 'dich-vu-forex-rebates-hoan-phi-forex-la-gi',
    title: {
      rendered: `Dịch vụ hoàn phí Forex/ Crypto là gì?`,
    },
    date: '2023-09-12T08:00:00',
    excerpt: { rendered: 'Tìm hiểu hoàn phí/backcom khi giao dịch Forex, Crypto là gì? Backcom từ đâu mà có và làm thế nào để nhận được backcom.' },
    thumbnail:
      'https://agileops-gafv-dev-videos.s3.amazonaws.com/thumbnail/z5985097178650_a7357efeae6eb7dcb21b4d362a69ac19.jpg',
    slug: 'dich-vu-forex-rebates-hoan-phi-forex-la-gi',
    author: {
      name: 'Đạo Trần',
      slug: 'Dao-Tran',
      acf: {
        author_path: 'Dao-Tran',
        avatar:
          'https://d3gxgptuxgz1ri.cloudfront.net/wp-content/uploads/2021/08/bc6635c21fd9e887b1c8-1.jpg',
        email: 'daotran97.blog@gmail.com',
      },
    },
    categories: [72, 174],
  });

  let postsCateReview = await getPostsHaveCategoriesAndSize({
    categories: [89],
    size: 5,
  });

  let postsCateAnnounceBroker = await getPostsHaveCategoriesAndSize({
    categories: [65],
    size: 5,
  });
  postsCateAnnounceBroker.push({
    id: 'phong-van-doc-quyen-giam-doc-dupoin',
    title: {
      rendered: `<span>VnRebates phỏng vấn độc quyền giám đốc Dupoin Markets</span> <span class='magazine'>Magazine</span>`,
    },
    date: '2024-09-12T08:00:00',
    excerpt: { rendered: '' },
    thumbnail:
      'https://agileops-gafv-dev-videos.s3.amazonaws.com/thumbnail/z5822629879581_3f269b673a8f4fc770cf9cdb0b986116.jpg',
    slug: 'phong-van-doc-quyen-giam-doc-dupoin',
    author: {
      name: 'Đạo Trần',
      slug: 'Dao-Tran',
    },
    categories: [1445, 4],
  });

  postsCateAnnounceBroker.push({
    id: 'phong-van-ceo-connext-tai-su-kien-ra-mat-chinh-thuc-cua-connext-tai-viet-nam',
    title: {
      rendered: `<span>Phỏng vấn CEO Connext tại sự kiện ra mắt chính thức Connext Việt Nam</span> <span class='magazine'>Magazine</span>`,
    },
    date: '2024-09-17T09:30:00',
    excerpt: { rendered: '' },
    thumbnail:
      'https://agileops-gafv-dev-videos.s3.amazonaws.com/bannerAds/z5850013967412_62ab0444df6e75094a6e8d2b2dae0526.jpg',
    slug: 'phong-van-ceo-connext-tai-su-kien-ra-mat-chinh-thuc-cua-connext-tai-viet-nam',
    author: {
      name: 'Đạo Trần',
      slug: 'Dao-Tran',
    },
    categories: [1445, 4],
  });

  let postsCateReviewBroker = postsCateAnnounceBroker
    .concat(postsCateReview)
    .sort((a, b) => b.date.localeCompare(a.date));
  postsCateReviewBroker = filterOnlyNeededDataPostGroup(postsCateReviewBroker);

  let postsCateTradingForex = await getPostsHaveCategoriesAndSize({
    categories: [58, 72],
    size: 5,
  });
  postsCateTradingForex = filterOnlyNeededDataPostGroup(postsCateTradingForex);

  let postsCateTradequy = await getPostInCategoriesAndSize({
    categories: [33, 175],
    size: 5,
  });
  postsCateTradequy = filterOnlyNeededDataPostGroup(postsCateTradequy);

  //menu feature posts
  const featurePostGroups = await getFeaturePostsCache();

  return {
    props: {
      newSlickBrokers,
      newForexBroker,
      newCryptoBroker,
      postsCateTradingForex,
      postsCateTradingCrypto,
      postsCateReviewBroker,
      postsCateTradequy,
      featurePostGroups,
    },
  };
};

const HomePage = (props) => {
  const [isBannerHidden, setIsBannerHidden] = useState(false);
  const [banner, setBanner] = useState({});
  const [products, setProducts] = useState([]);
  const { locale } = useRouter();
  const { ref } = getQueryParams();
  const translate = useTranslate();
  const [, showModal] = useModal(POPUP_ID);
  const { user } = useAuth();
  
  const checkAgencyCode = async (refCode) => {
    const res = await checkValidAgencyCode(refCode);
    if (res?.data) {
      setCookie('agency-code', refCode, 30);
    }
    return;
  };

  const getAirdropHomePage = async () => {
    const res = await getConfig("AIRDROP_HOMEPAGE");
    
    if (res?.data?.name) {
      setBanner(res?.data)
    } else {
      setBanner({})
    }
  }
  
  const handleGetHaravanProducts = async () => {
    const res = await getHaravanProduct();
    if (res?.data?.products) {
      setProducts(res?.data?.products);
    } else {
      setProducts([]);
    }
  };

  useEffect(() => {
    if (!ref || ref === '') return;

    checkAgencyCode(ref);
  }, [ref]);

  useEffect(() => {
    handleGetHaravanProducts();
    getAirdropHomePage();
  }, []);

  return (
    <>
      <SEO
        canonical={DOMAIN}
        title={translate.home.title}
        description={translate.home.description}
      />
      <HeaderBanner setIsBannerHidden={setIsBannerHidden} />
      <SlickBroker brokers={props.newSlickBrokers} translate={translate} locale={locale} />
      <Menu
        featurePostGroups={props.featurePostGroups}
        translate={translate}
        isBannerHidden={isBannerHidden}
      />

      <h1 className={styles.homepageH1}>
        VnRebates - Kiến thức Trading chuyên sâu Forex, Crypto, Hàng hoá
      </h1>

      <div className={styles.container}>
        <div className={styles.paddingContent}>
          <Row>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 16 }}
              lg={{ span: 16 }}
              className={styles.homeTopSlider}
            >
              {locale === 'vi' ? <HomeTopSlider /> : <HomeSliderForeign translate={translate} />}
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}
              className={styles.minorBanners}
            >
              <VnrLink to={banner?.value} style={{position: 'relative', width: '424px'}}>
                <div className={styles.countdownSection}>
                  <AirdropCountdown targetDate={dayjs(banner?.countdownTime || null).valueOf()} />
                </div>
                <ImageThumbnailRatio src={banner?.imageUrl} ratio="424:214" alt="banner academy" additionStyle={{maxWidth: '424px', borderRadius: '12px'}} />
              </VnrLink>
              <VnrLink to="/trading-contest/hoc-that-trade-that-nhan-thuong-1000$" style={{width: '424px'}}>
                <ImageThumbnailRatio src="/images/3.jpg" ratio="424:214" alt="banner signal" additionStyle={{maxWidth: '424px', borderRadius: '12px'}} />
              </VnrLink>
            </Col>
          </Row>

          <AcademySellingPoint />

          <Row style={{ width: '100%' }} gutter={32}>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
              <HaravanProduct
                title="Khoá học video"
                products={products.filter((item) => item.product_type === 'Khóa học Video')}
              />
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
              <HaravanProduct
                title="Bộ thẻ Flashcard"
                products={products.filter((item) => item.product_type === 'Flashcard')}
              />
            </Col>
          </Row>
        </div>

        <div className={styles.brokerList}>
          <div>
            <BrokerList
              key="forexBrokerList"
              id="forexBrokerList"
              brokerList={props.newForexBroker}
              title={translate.home.forexBrokerListTitle}
              linkLoadMore="/forex-broker"
              translate={translate}
              locale={locale}
            />
          </div>
          <div className={styles.backcomWrapper}>
            <div className={styles.backcom}>
              <div className={styles.backcomTitle}>Dịch vụ hoàn tiền / Backcom</div>
              <div className={styles.backcomKey}>KHÔNG nâng spread, KHÔNG chi phí ẩn</div>
              <div className={styles.backcomKey}>
                Hoàn tiền tự động tới 95% - Sàn thuộc Top 10 Forex & Crypto thế giới
              </div>
            </div>
            <div onClick={() => showModal({ email: user?.email || '' })} className={styles.registerButton}>
              Đăng ký
            </div>
          </div>
          <div>
            <BrokerList
              key="cryptoList"
              id="cryptoBrokerList"
              brokerList={props.newCryptoBroker}
              title={translate.home.cryptoBrokerListTitle}
              linkLoadMore="/crypto-broker"
              translate={translate}
              locale={locale}
            />
          </div>
        </div>

        <MiddleBanner />

        <div className={styles.telegramDirectional}>
          <VnrLink to="https://t.me/vnrebates">
          <Row>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
              <div className={styles.telegramDesc}>
                Cập nhật việc Ứng dụng Kiến thức Trading vào thực chiến Forex, Crypto nhanh nhất tại
                đây
              </div>
              {!isMobile && (
                <VnrLink to="https://t.me/vnrebates" className={styles.telegramButton}>
                  <IconTelegram />
                  <div className={styles.telegramButtonText}>Telegram</div>
                </VnrLink>
              )}
            </Col>
            <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 0 }} lg={{ span: 0 }}>
              <VnrLink to="https://t.me/vnrebatessupport" className={styles.telegramButton}>
                <IconTelegram />
                <div className={styles.telegramButtonText}>Telegram</div>
              </VnrLink>
            </Col>
            <Col span={12}>
              <ImageThumbnailRatio src="/images/Telegram.svg" ratio="399:136" />
            </Col>
          </Row>
          </VnrLink>
        </div>

        <div className={styles.paddingContent}>
          <Story />
          <div className={styles.postGroups}>
            <NewPostGroup
              groupTitle="Kiến thức Trading
Forex/Crypto"
              posts={props.postsCateTradingForex}
              linkLoadMore="/forex/kien-thuc"
              isShowExcerptFirstPost={false}
            />

            <NewPostGroup
              groupTitle="Review - Bonus sàn 
Forex/Crypto"
              posts={props.postsCateReviewBroker}
              linkLoadMore="/review"
              isShowExcerptFirstPost={false}
            />

            <NewPostGroup
              groupTitle="Hoàn phí (Backcom)
Forex/Crypto"
              posts={props.postsCateTradingCrypto}
              linkLoadMore="/backcom-forex-crypto"
              isShowExcerptFirstPost={false}
            />

            <NewPostGroup
              groupTitle="Ứng dụng 
Khoá học Trading"
              posts={props.postsCateTradequy}
              linkLoadMore="/ung-dung-kien-thuc-khoa-hoc"
              isShowExcerptFirstPost={false}
            />
          </div>
          <div className={styles.suMenhWrapper}>
            <div className={styles.suMenhImage}>
              <ImageThumbnailRatio src="/images/Provision.svg" ratio="360:372" />
            </div>
            <div className={styles.suMenh}>
              <div className={styles.vnrebates}>VỀ VNRebates</div>
              <div className={styles.vnrebatesBorderBottom}></div>
              <div className={styles.suMenhContent}>
                <div>
                  “Tự chủ Trading, Tự do Cuộc sống” là mục tiêu mà VnRebates luôn hướng tới. Chúng
                  mình tin rằng sự tự do thực sự không chỉ nằm ở tài chính mà còn là sự tự do trong
                  tư duy và các quyết định của bạn. Để đạt được điều đó, trader cần tự chủ trong
                  kiến thức và kỹ năng giao dịch thay vì phụ thuộc vào người khác / tín hiệu... Với
                  hơn 10 năm kinh nghiệm, VnRebates cam kết cung cấp các phương pháp trading thực
                  chiến, cập nhật và hệ thống hóa từ những trader hàng đầu thế giới để đồng hành
                  cùng bạn trên con đường trở thành một trader độc lập, tự chủ và thành công.
                </div>
                <div>
                  <highlight>
                    Phương pháp nào thật sự tốt và áp dụng được VnRebates sẽ tổng hợp lại
                    <br />
                    Các sản phẩm dịch vụ nào tốt VnRebates sẽ mang về
                    <br />
                    ... và Broker nào tốt VnRebates sẽ nhận Hoàn phí lại cho traders.
                  </highlight>
                </div>
                <div>
                  Chúng tôi mong muốn tạo ra một Thế hệ nhà đầu tư/Traders có kiến thức nền tảng tốt
                  và đầu tư một cách khoa học, thừa hưởng được những tinh hoa của Kiến thức đầu tư
                  trên thế giới. Hy vọng VnRebates sẽ là Đối tác tin cậy cùng đồng hành và giúp bạn
                  vững bước hơn trên con đường đầu tư của mình.
                </div>
                <div>Trân trọng!</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer translate={translate} />

      <LayoutPopup />
      <PopupUserSentInformation />
      <ProfileUpdateInfoPopup />

      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `  
          {
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "VnRebates",
            "url": "${DOMAIN}",
            "logo": "${DOMAIN}/images/logo.png",
            "contactPoint": [{
                            "@type": "ContactPoint",
                            "telephone": "090 982 55 34",
                            "contactType": "customer service"
                          }],
            "sameAs": [
              "https://www.facebook.com/VnRebates/",
              "https://twitter.com/vnrebates_net",
              "https://www.instagram.com/vnrebates/",
              "https://www.youtube.com/channel/UCpCt75O_kcHwwkW5mgf45Mw",
              "https://www.linkedin.com/company/vnrebates/"
            ]
          }
          `,
        }}
      />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `  
          {
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "VnRebates",
            "image": "${DOMAIN}/images/logo.png",
            "@id": "${DOMAIN}/",
            "url": "${DOMAIN}/",
            "telephone": "090 982 55 34",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "235 Hoa Sứ, Phường 7, Phú Nhuận",
              "addressLocality": "Thành phố Hồ Chí Minh",
              "postalCode": "700000",
              "addressCountry": "VN"
            },
            "geo": {
              "@type": "GeoCoordinates",
              "latitude": 10.7972726,
              "longitude": 106.6882684
            },
            "openingHoursSpecification": {
              "@type": "OpeningHoursSpecification",
              "dayOfWeek": [
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
                "Sunday"
              ],
              "opens": "00:00",
              "closes": "23:59"
            },
            "sameAs": [
              "https://www.facebook.com/VnRebates/",
              "https://twitter.com/vnrebates_net",
              "https://www.instagram.com/vnrebates/",
              "https://www.youtube.com/channel/UCpCt75O_kcHwwkW5mgf45Mw",
              "https://www.linkedin.com/company/vnrebates/"
            ] 
          }          
          `,
        }}
      />
    </>
  );
};
export default HomePage;
